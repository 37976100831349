export const GLOBAL_TITLE = 'GLOBAL_TITLE';
export const GLOBAL_TYPE = 'GLOBAL_TYPE';
export const GLOBAL_EDITED = 'GLOBAL_EDITED';
export const GLOBAL_VALID = 'GLOBAL_VALID';
export const GLOBAL_IMAGES = 'GLOBAL_IMAGES';
export const REMOVE_GLOBAL_IMAGE = 'REMOVE_GLOBAL_IMAGE';
export const UPDATE_FORM_ID = 'UPDATE_FORM_ID';
export const GLOBAL_HELP_VISIBLE = 'GLOBAL_HELP_VISIBLE';
export const GLOBAL_HELP_POSITION_X = 'GLOBAL_HELP_POSITION_X';
export const GLOBAL_HELP_TYPE = 'GLOBAL_HELP_TYPE';
export const SET_MODULES_COLLAPSED = 'SET_MODULES_COLLAPSED';
